import img11 from "assets/icons/pool/bnb.png"
import img12 from "assets/icons/pool/busd.webp"
import img13 from "assets/icons/pool/cake.png"
import img15 from "assets/icons/pool/baby-doge.png"
import img16 from "assets/icons/pool/trust-wallet.png"
import img17 from "assets/icons/pool/gmt.webp"
import img18 from "assets/icons/pool/c98.png"

const tokensText = [
  {
    token: "BUSD",
    tokenDesc: "Binance USD (BUSD) is a stablecoin issued by Binance, designed to maintain a stable value of 1 USD per BUSD token, useful for transactions and trading.",
    ca: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    site: "https://www.binance.com/"
  },
  {
    token: "CAKE",
    tokenDesc: "CAKE is the governance token for PancakeSwap, with an unlimited supply that is managed through regular token burns to increase deflation.",
    ca: "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
    site: "https://pancakeswap.finance/"
  },
  {
    token: "BABYDOGE",
    tokenDesc: "Baby Doge Coin is a BSC meme token created for a community of Doge lovers.",
    ca: "0xc748673057861a797275cd8a068abb95a902e8de",
    site: "https://babydoge.com/"
  },
  {
    token: "TWT",
    tokenDesc: "Trust Wallet Token (TWT) is a BEP-20 utility token that offers benefits and incentives to Trust Wallet users, a mobile cryptocurrency wallet.",
    ca: "0x4b0f1812e5df2a09796481ff14017e6005508003",
    site: "https://trustwallet.com/"
  },
  {
    token: "GMT",
    tokenDesc: "GMT is the native token of the FSL ecosystem, with a fixed supply of 6 billion tokens, used across all FSL products to unlock various functions and perks.",
    ca: "0x3019bf2a2ef8040c242c9a4c5c4bd4c81678b2a1",
    site: "https://www.stepn.com/"
  },
  {
    token: "C98",
    tokenDesc: "Coin98 is a decentralized finance (DeFi) solution providing cross-chain swaps, staking, and yield farming, aiming to bridge TradFi users to DeFi services.",
    ca: "0xaec945e04baf28b135fa7c640f624f8d90f1c3a6",
    site: "https://coin98.com/"
  }
]
export const poolDataSeparatedBsc = [
  {
    icon: img11,
    name: "Binance",
    token: "BNB",
    id: "binancecoin",
    address: "0x7694399cba11469fdbba450917f0dec53b1fdf44",
    day: "3",
    index: 1,
    percent: "0.2",
    text: tokensText[0],
  },
  {
    icon: img11,
    name: "Binance",
    token: "BNB",
    id: "binancecoin",
    address: "0x694446a2647e384fd33a3b6e513a6475f77379af",
    day: "14",
    index: 2,
    percent: "1.5",
    text: tokensText[0],
  },
  {
    icon: img11,
    name: "Binance",
    token: "BNB",
    id: "binancecoin",
    address: "0xdb2e9a3a222697b8c808fe04847bc5fdc43175f4",
    day: "30",
    index: 3,
    percent: "4",
    text: tokensText[0],
  },
  {
    icon: img11,
    name: "Binance",
    token: "BNB",
    id: "binancecoin",
    address: "0xaa5bc284e38d1eed890d6de8fdba10c30967ecd1",
    day: "60",
    index: 4,
    percent: "9",
    text: tokensText[0],
  },
  {
    icon: img11,
    name: "Binance",
    token: "BNB",
    id: "binancecoin",
    address: "0x536d770b8c5e49fb3d4845afc2ad3aa752b01b62",
    day: "90",
    index: 5,
    percent: "15",
    text: tokensText[0],
  },
  {
    icon: img12,
    name: "Binance USD",
    token: "BUSD",
    id: "binance-usd",
    address: "0x0f0231b43ee64d53dcae20b89783ee78a48bbc24",
    addAddress: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    day: "3",
    index: 1,
    percent: "0.15",
  },
  {
    icon: img12,
    name: "Binance USD",
    token: "BUSD",
    id: "binance-usd",
    address: "0x4f3126d5de26413abdcf6948943fb9d0847d9818",
    addAddress: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    day: "14",
    index: 2,
    percent: "0.8",
    text: tokensText[1],
  },
  {
    icon: img12,
    name: "Binance USD",
    token: "BUSD",
    id: "binance-usd",
    address: "0x169f653a54acd441ab34b73da9946e2c451787ef",
    addAddress: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    day: "30",
    index: 3,
    percent: "2",
    text: tokensText[1],
  },
  {
    icon: img12,
    name: "Binance USD",
    token: "BUSD",
    id: "binance-usd",
    address: "0xa82bfe1f2981cce300ae47b05b24e22ad7be2598",
    addAddress: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    day: "60",
    index: 4,
    percent: "5",
    text: tokensText[1],
  },
  {
    icon: img12,
    name: "Binance USD",
    token: "BUSD",
    id: "binance-usd",
    address: "0x2dfe725eca8ffe13fe4e4a8e015cf857b3b72bcf",
    addAddress: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    day: "90",
    index: 5,
    percent: "8.5",
    text: tokensText[1],
  },
  {
    icon: img13,
    name: "PancakeSwap",
    token: "CAKE",
    id: "pancakeswap-token",
    address: "0xd7ec6481a8722c9a06a6da1b2d024f4aa46d6a95",
    addAddress: "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
    day: "14",
    index: 2,
    percent: "4",
    text: tokensText[2],
  },
  {
    icon: img13,
    name: "PancakeSwap",
    token: "CAKE",
    id: "pancakeswap-token",
    address: "0x4a51749d89eb4a66b1867b5fe4c56cb04311b486",
    addAddress: "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
    day: "30",
    index: 3,
    percent: "10",
    text: tokensText[2],
  },
  {
    icon: img13,
    name: "PancakeSwap",
    token: "CAKE",
    id: "pancakeswap-token",
    address: "0xc54d35a8cfd9f6dae50945df27a91c9911a03ab1",
    addAddress: "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
    day: "60",
    index: 4,
    percent: "22",
    text: tokensText[2],
  },
  {
    icon: img13,
    name: "PancakeSwap",
    token: "CAKE",
    id: "pancakeswap-token",
    address: "0x91b3927f100bb6c19e5434bfaba07d60670b98d6",
    addAddress: "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
    day: "90",
    index: 5,
    percent: "36",
    text: tokensText[2],
  },
  {
    icon: img15,
    name: "Baby Doge Coin",
    token: "BABYDOGE",
    id: "baby-doge-coin",
    address: "0x97219afd7f2a54c82601567305ed54f6eac7d41f",
    addAddress: "0xc748673057861a797275cd8a068abb95a902e8de",
    day: "14",
    index: 2,
    percent: "1.4",
    text: tokensText[3],
  },
  {
    icon: img15,
    name: "Baby Doge Coin",
    token: "BABYDOGE",
    id: "baby-doge-coin",
    address: "0x61da7b8437686df313134b4eb299562543817802",
    addAddress: "0xc748673057861a797275cd8a068abb95a902e8de",
    day: "30",
    index: 3,
    percent: "3",
    text: tokensText[3],
  },
  {
    icon: img15,
    name: "Baby Doge Coin",
    token: "BABYDOGE",
    id: "baby-doge-coin",
    address: "0xe356fe28b7b6b015a3b2bb4419dbdf2777d7420b",
    addAddress: "0xc748673057861a797275cd8a068abb95a902e8de",
    day: "60",
    index: 4,
    percent: "7",
    text: tokensText[3],
  },
  {
    icon: img15,
    name: "Baby Doge Coin",
    token: "BABYDOGE",
    id: "baby-doge-coin",
    address: "0x8e9e89c1c4807b7059436e468bb0082f76e6d02f",
    addAddress: "0xc748673057861a797275cd8a068abb95a902e8de",
    day: "90",
    index: 5,
    percent: "11",
    text: tokensText[3],
  },
  {
    icon: img16,
    name: "Trust Wallet",
    token: "TWT",
    id: "trust-wallet-token",
    address: "0xa1f9ae3b751e20fb0a02ee64b42dff537662a9c0",
    addAddress: "0x4b0f1812e5df2a09796481ff14017e6005508003",
    day: "14",
    index: 2,
    percent: "1.4",
    text: tokensText[4],
  },
  {
    icon: img16,
    name: "Trust Wallet",
    token: "TWT",
    id: "trust-wallet-token",
    address: "0x5142609a89b454bc9e1aac2dc0d0b2aaa7b20b2b",
    addAddress: "0x4b0f1812e5df2a09796481ff14017e6005508003",
    day: "30",
    index: 3,
    percent: "3",
    text: tokensText[4],
  },
  {
    icon: img16,
    name: "Trust Wallet",
    token: "TWT",
    id: "trust-wallet-token",
    address: "0x00c44e80e4bb80c175a38afde0150adbaecff303",
    addAddress: "0x4b0f1812e5df2a09796481ff14017e6005508003",
    day: "60",
    index: 4,
    percent: "7",
    text: tokensText[4],
  },
  {
    icon: img16,
    name: "Trust Wallet",
    token: "TWT",
    id: "trust-wallet-token",
    address: "0xae1314d6fbd2de2c26a7164ddd1fede5544b06cc",
    addAddress: "0x4b0f1812e5df2a09796481ff14017e6005508003",
    day: "90",
    index: 5,
    percent: "11",
    text: tokensText[4],
  },
  {
    icon: img17,
    name: "STEPN",
    token: "GMT",
    id: "stepn",
    address: "0xb7d0749a64345552ef01ff54d6864202215a09a1",
    addAddress: "0x3019bf2a2ef8040c242c9a4c5c4bd4c81678b2a1",
    day: "14",
    index: 2,
    percent: "2",
    text: tokensText[5],
  },
  {
    icon: img17,
    name: "STEPN",
    token: "GMT",
    id: "stepn",
    address: "0x9c2c266620fed733dffcbef6121597b46b4f55ca",
    addAddress: "0x3019bf2a2ef8040c242c9a4c5c4bd4c81678b2a1",
    day: "30",
    index: 3,
    percent: "5",
    text: tokensText[5],
  },
  {
    icon: img17,
    name: "STEPN",
    token: "GMT",
    id: "stepn",
    address: "0xe4d3df079fbef6529c893ee4e9298711d480ff35",
    addAddress: "0x3019bf2a2ef8040c242c9a4c5c4bd4c81678b2a1",
    day: "60",
    index: 4,
    percent: "11",
    text: tokensText[5],
  },
  {
    icon: img17,
    name: "STEPN",
    token: "GMT",
    id: "stepn",
    address: "0xb99b2ad71489d282871df3ca731d7a1479ab58d6",
    addAddress: "0x3019bf2a2ef8040c242c9a4c5c4bd4c81678b2a1",
    day: "90",
    index: 5,
    percent: "18",
    text: tokensText[5],
  },
  {
    icon: img18,
    name: "Coin98",
    token: "C98",
    id: "coin98",
    address: "0x2f22bd3dff4bed265c49e140752607be80de11bc",
    addAddress: "0xaec945e04baf28b135fa7c640f624f8d90f1c3a6",
    day: "14",
    index: 2,
    percent: "1.5",
    text: tokensText[6],
  },
  {
    icon: img18,
    name: "Coin98",
    token: "C98",
    id: "coin98",
    address: "0x80076a3ecaa5ec81335b208805b7371074504965",
    addAddress: "0xaec945e04baf28b135fa7c640f624f8d90f1c3a6",
    day: "30",
    index: 3,
    percent: "4",
    text: tokensText[6],
  },
  {
    icon: img18,
    name: "Coin98",
    token: "C98",
    id: "coin98",
    address: "0xdeef61bed60c31aa156da22938c66fef9af8bec1",
    addAddress: "0xaec945e04baf28b135fa7c640f624f8d90f1c3a6",
    day: "60",
    index: 4,
    percent: "9",
    text: tokensText[6],
  },
  {
    icon: img18,
    name: "Coin98",
    token: "C98",
    id: "coin98",
    address: "0x52a258ed593c793251a89bfd36cae158ee9fc4f8",
    addAddress: "0xaec945e04baf28b135fa7c640f624f8d90f1c3a6",
    day: "90",
    index: 5,
    percent: "15",
    text: tokensText[6],
  },
]

import React, {useEffect, useState} from "react"

import "./Leverage.scss"
import {animateNumber} from "../../utils/countToFunction";

type Props ={
  title: number
  desc: string
  scrollPosition: boolean
}

export const LeverageItem = ({title, desc, scrollPosition}:Props): JSX.Element => {
  const [displayNumber, setDisplayNumber] = useState(0)
  useEffect(() => {
    console.log(scrollPosition)
    if (scrollPosition) {
      animateNumber(value => {
        setDisplayNumber(Math.floor(value))
      }, 0, title, 5000);
    }
  }, [scrollPosition])

  return (
    <div className="leverage-content-item">
      <div className='leverage-content-item-right'>
        <div className="leverage-content-item-title">{displayNumber}+</div>
        <div className="leverage-content-item-desc">{desc}</div>
      </div>
    </div>
  )
}

import imgTeam3Src from "../../assets/images/team/2.jpeg"
import imgTeam4Src from "../../assets/images/team/3.jpeg"
import imgTeam5Src from "../../assets/images/team/4.jpeg"
import imgTeam6Src from "../../assets/images/team/5.jpeg"
import imgTeam7Src from "../../assets/images/team/6.jpeg"

export const data = [
  {
    img: imgTeam3Src,
    title: "Alan Sandon",
    desc: "General Manager",
  },
  {
    img: imgTeam4Src,
    title: "Tim Warnock",
    desc: "Senior Solidity Engineer",
  },
  {
    img: imgTeam5Src,
    title: "Noel Brennan",
    desc: "Senior Blockchain Engineer",
  },
  {
    img: imgTeam6Src,
    title: "Ricky Dhot",
    desc: "Senior Blockchain Engineer",
  },
  {
    img: imgTeam7Src,
    title: "Arvinder Virk",
    desc: "Marketing & Community Manager",
  },
]

export const hrefs = {
  facebook: "",
  instagram: "",
  twitter: "https://x.com/Bitstaker_",
  youtube: "",
  discord: "",
  scan: "",
  telegram: "https://t.me/bitstaker_tg",
  linkedIn: "",
  reddit: "",
  medium: "",
  address: "",
  docs: "",
  whitePaper: "",
  contract: "",
  audit: ""
}

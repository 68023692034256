import React from "react"

import "./Main.scss"
import {  useSearchParams } from "react-router-dom"
import { routes } from "../../utils"
import { useMedia } from "use-media"
import srcPath from "assets/pdfs/pdf.pdf"
import {SimpleButton} from "../SimpleButton";
import { TypeAnimation } from 'react-type-animation';


export const Main = (): JSX.Element => {
  const [search] = useSearchParams()
  const isM = useMedia({ maxWidth: 1200 })

  return (
    <main className="main animate__animated animate__fadeInLeft">
      <h1 className="main-left-title">
        Experience <span className="colored">Bitstaker</span>,<br/>
        your gateway to unbounded<br/>
        earnings in staking
      </h1>
      <p className="main-left-desc">Powered by our robust smart contracts.<br/>
        Stake, Earn, Soar with Bitstaker</p>
      <div className="main-left-buttons">
        <SimpleButton text="Stake Now" href={`${routes.farms}?${search.toString()}`} />
        {isM ? (
          <a download href={srcPath} className="main-left-buttons-item right">
            Litepaper
          </a>
        ) : (
          <SimpleButton variant='outlined' text='Litepaper' href={`${routes.docs}?${search.toString()}`} />
        )}
      </div>
    </main>
  )
}

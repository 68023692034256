export const routes = {
  index: "/",

  farms: "/farms",
  trademark: "/trademark",
  team: "/team",
  faq: "/faq",
  terms: "/terms",
  privacy: "/privacy",
  cookies: "/cookies",
  referral: "/referral",
  pool: "/pool",
  page: "/page",
  swap: "/swap",
  news: "/news",
  swapInfo: "/analytics",
  token: "/token",
  poolInfo: "/pool-info",
  docs: "/litepaper",
  stake: "/stake",
  earn: "/earn",
  roadmap: "/roadmap",
  tokensData: "/tokens-data",
  priceGraf: "/price-graph",
}

import React, { useEffect, useState } from "react"
import {
  Header,
  Footer,
  Loading, NewsHome,
} from "components"

import "./Roadmap.scss"
import arrowSrc from "assets/icons/roadmap-arrow.svg"
import road1Src from "assets/icons/road/1.svg"
import road2Src from "assets/icons/road/2.svg"
import road3Src from "assets/icons/road/3.svg"
import imgMainBgSrc from "../../assets/images/main-bg.webp";
import videoSrc from "../../assets/video/video.mp4";

export const Roadmap = (): JSX.Element => {
  const [loaded, setLoaded] = useState(false)

  const journeyItems = [
    {
      title: "Phase 1: Aug'23 - Mar'24",
      desc: [
        "Launch of Bitstaker platform supporting staking on multiple chains.",
        "Initial integration with BSC and Ethereum chains.",
        "Implementation of secure staking mechanisms and smart contract audits.",
        "User feedback collection and continuous improvement of the platform based on community input.",
      ],
    },
    {
      title: "Phase 2: Apr'24 - Sep'24",
      desc: [
        "Optimization of staking algorithms to maximize rewards and efficiency.",
        "Introduction of advanced features such as liquidity mining, yield farming, and governance.",
        "Collaboration with other DeFi projects and platforms to enhance interoperability and ecosystem growth.",
        "Launch of educational resources and tutorials to onboard new users and increase adoption.",
        "Implementation of security upgrades and regular audits to ensure the safety of user funds.",
      ],
    },
    {
      title: "Phase 3: Oct'24 - Mar'25",
      desc: [
        "Research and development of novel staking mechanisms and DeFi products to stay ahead of the curve.",
        "Integration with emerging blockchain technologies and layer 2 solutions to improve scalability and reduce transaction costs.",
        "Initial integration with Optimism, Polygon, Avalanche, Fantom, and Arbitrum chains.",
        "Expansion into new markets and regions to reach a broader user base and promote financial inclusion.",
      ],
    },
    {
      title: "Phase 4: Apr'25 - Sep'25",
      desc: [
        "Collaboration with regulatory bodies and compliance experts to ensure legal compliance and regulatory clarity.",
        "Initial integration with Aptos, Manta and Base chains.",
        "Continued community engagement and governance participation to empower users and decentralize decision-making.",
        "Continuous iteration and improvement based on market trends, technological advancements, and community feedback.",
        "Governance and DAO"
      ],
    },
  ]

  const roadBlocks = [
    {
      image: road1Src,
      title: "Maximum APR",
      desc: "Our smart contracts provide maximum available return on your assets."
    },
    {
      image: road2Src,
      title: "Multichain Staking",
      desc: "We provide options to stake your assets on multiple Blockchains."
    },
    {
      image: road3Src,
      title: "Referral",
      desc: "Referring your friends provide you with extra rewards, benefiting you both."
    },
  ]

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true)
    }, 1000)
  }, [])

  return (
    <div className="roadmap" style={{backgroundImage: `url(${imgMainBgSrc})`}}>
      {!loaded && <Loading/>}
      <video className="home-video" playsInline muted autoPlay preload='auto'
             loop id="video">
        <source src={videoSrc} type="video/mp4"/>
      </video>
      <div className='roadmap-blur'/>
      <Header/>
      <div className='news-prices'>
        {
          // @ts-ignore
          (<coingecko-coin-price-marquee-widget
            coin-ids="bitcoin,ethereum,weth,binancecoin,usd-coin,uniswap,chainlink,wrapped-bitcoin,apecoin,tether,pancakeswap-token,baby-doge-coin,trust-wallet-token,stepn,coin98,aptos,optimism,matic-network,avalanche-2,arbitrum,chainlink,manta-network,fantom,dydx-chain"
            currency="usd"
            font-color="#ffffff"
            background-color="rgba(12, 14, 40, 0.32)"
            locale="en"/>)
        }
      </div>
      <section className='roadmap-main animate__animated animate__fadeInLeft'>
        <h1>1 Billion Mission</h1>
        <p>
          We are on a mission to bring 1 BILLION people to staking and DeFi. We aim to achieve this by offering the best
          and convenient way to earn staking rewards and participate in DeFi opportunities.
        </p>
      </section>
      <section className='roadmap-journey animate__animated animate__fadeInLeft'>
        <h3>
          Journey so far
        </h3>
        <div className='roadmap-journey-content'>
          {/*<div className='roadmap-journey-content-line'/>*/}
          <img src={arrowSrc} alt='arrow' className='roadmap-journey-content-bg'/>
          <div className='roadmap-journey-content-items'>
            {journeyItems.map((item, index) => (
              <div key={index} className='roadmap-journey-content-item'>
                <div className='roadmap-journey-content-item-dot'/>
                <ul className='roadmap-journey-content-item-ul'>
                  {item.title}
                  <div className='roadmap-journey-content-item-ul-bg'>
                    {item.desc.map((itemDesc, indexDesc) => (
                      <li key={indexDesc} className='roadmap-journey-content-item-li'>
                        {itemDesc}
                      </li>
                    ))}
                  </div>
                </ul>
              </div>
            ))}
          </div>

        </div>
      </section>
      <section data-aos="fade-up" className='roadmap-road'>
        <h3>
          The road ahead
        </h3>
        <div className='roadmap-road-content'>
          {roadBlocks.map((item, index) => (
            <div className='roadmap-road-content-item' key={index}>
              <div className='roadmap-road-content-item-icon'>
                <img src={item.image} alt='road icon'/>
              </div>
              <dl>
                <dt>{item.title}</dt>
                <dd>{item.desc}</dd>
              </dl>
            </div>
          ))}
        </div>
      </section>
      {/*<NewsHome />*/}
      <Footer/>
    </div>
  )
}
